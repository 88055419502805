<template>
  <div class="input__w" :class="{ wide }">
    <div class="input">
      <textarea
        v-model.trim="getValue"
        class="input__field"
        rows="4"
        :maxlength="maxlength"
        :minlength="minlength"
        :class="[
          { filled: getValue },
          { error: (validate.$error && validate.$dirty) || customError },
        ]"
        @blur="onBlur"
      />
      <span class="input__caption" :class="{ required }">
        {{ _T(caption) }}
      </span>
      <div
        v-show="(validate.$error && validate.$dirty) || customError"
        class="input__error-img"
      >
        <img src="~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div
      v-show="(validate.$error && validate.$dirty) || customError"
      class="input__error-caption-w"
    >
      <p v-if="null !== customError" class="input__error-caption">
        {{ customError }}
      </p>
      <p
        v-else-if="validate.$error && validate.$dirty"
        class="input__error-caption"
      >
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  caption: { type: String, required: true },
  validate: { type: Object, required: true },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  customError: { required: false, default: null },
  modelValue: { type: String, required: false },
  required: { type: Boolean, required: false, default: true },
  wide: { type: Boolean, required: false, default: true },
});

const emits = defineEmits(["update:modelValue"]);

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    if (0 === value.trim().length) {
      return emits("update:modelValue", null);
    }

    emits("update:modelValue", value);
  },
});

function onBlur() {
  props.validate.$touch();
}
</script>

<style lang="scss" scoped>
.input__caption {
  left: 16px;
  top: 18px;
}

.input__field {
  resize: none;
}

.input {
  border-radius: 16px;
}
</style>
